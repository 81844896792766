import { useEffect } from "react"
import { navigate } from "gatsby"

const IndexPage = () => {
  useEffect(() => {
    redirectIndexPage()
  }, [])
  const redirectIndexPage = () => {
    navigate("/muzak/")
  }
}

export default IndexPage